body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

img {
  max-width: 100%;
  max-height: 100%;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.fixed {
  position: fixed;
}

.position-relative {
  position: relative;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.vertical-top {
  vertical-align: top;
}

.title {
  font-size: 3em;
}

.details {
  font-size: large;
  line-height: 26px;
}

.container {
  margin: 0 auto;
  width: 96%;
}

.info-block-container {
  display: table-cell;
  vertical-align: middle;
  top: 50%;
  transform: translate(0, -50%);
  position: absolute;
}

.info-block {
  display: inline-block;
  vertical-align: middle;
  width: 50%;
}

@media (max-width:767px) {
  .info-block {
    display: block;
    width: 100%;
  }
}

@media (min-width:768px) {
  .modal {
    min-width: 460px;
  }

}

@media (min-width:1210px) {
  .container {
    width: 1200px;
  }

}