header {
	z-index: 666;
	top: 0;
	left: 0;
	right: 0;
}

.navigation {
	padding: 16px;
	background: white;
}

.logo {
	height: 16px;
}

.menu {
	float: right;
	padding: 0;
	margin: 0;
}

.menu li {
	padding: 8px;
	margin: 8px;
}

.menu li:last-child {
	margin-right: 0 !important;
}

.menu-item {
	font-size: smaller;
}

.menu li:hover {
	cursor: pointer;
	color: #3423C7;
	border-bottom: 2px solid #3423C7;
}

.menu-item-active {
	color: #3423C7;
	border-bottom: 2px solid #3423C7;
}

.btn-contact-container:hover {
	border-bottom: none !important;
}

.btn-contact {
	background: #014BB8;
	color: white;
	font-size: medium;
	font-weight: bold;
	text-decoration: none;
	padding: 8px 16px 8px 16px;
	border-radius: 4px;
}

.btn-contact:hover {
	background: #3423C7;
}