.about-content {
	min-height: 100%;
}

.about-content .details li {
	margin-top: 8px;
}

.copyright {
	font-size: small;
}