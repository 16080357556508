.customers-content {
	min-height: 100%;
}

.technologies {
	padding: 0;
}

.technologies li {
	margin: 8px;
}

.technologies img {
	height: 32px;
	vertical-align: middle;
}